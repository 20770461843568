import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Select, MenuItem, InputLabel, FormControl, CircularProgress, Typography } from '@mui/material';

const ClientSelectionDialog = ({ open, onClose, clients, onSelectClient, onSaveClient, selectedClient, loading }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: 400,
                    maxWidth: '400px',
                }
            }}
        >
            <DialogTitle sx={{ textAlign: 'center', textTransform: 'uppercase' }}>
                Choisir un client
            </DialogTitle>

            <DialogContent sx={{ paddingTop: '8px !important', paddingBottom: '4px !important' }}>
                <FormControl fullWidth>
                    <InputLabel id="select-client-label">Client</InputLabel>
                    <Select
                        labelId="select-client-label"
                        value={selectedClient}
                        onChange={onSelectClient}
                        label="Client"
                    >
                        {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                                {client.id} - {client.fbClientName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', marginTop: 0, marginBottom: 10 }}>
                <Button onClick={onClose} color="error" variant="outlined" sx={{ width: 150 }}>
                    Annuler
                </Button>
                <Button onClick={onSaveClient} color="success" variant="outlined" disabled={loading} sx={{ display: 'flex', alignItems: 'center', width: 150 }}>
                    {loading ? (
                        <CircularProgress color="inherit" size={24} sx={{ marginRight: 1 }} />
                    ) : (
                        'Enregistrer'
                    )}
                </Button>
            </DialogActions>

            <Typography
                variant="body2"
                sx={{
                    px: 2,
                    pb: 2,
                    textAlign: "center",
                    color: "gray",
                    fontSize: "12px",
                }}
            >
                Aucun client dans Freshbook enregistré pour cette entreprise. Veuillez le sélectionner le client pour cette entreprise.
            </Typography>
        </Dialog>
    );
};

export default ClientSelectionDialog;
