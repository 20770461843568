// export const lifecyclePhases = [
//   { "label": "À prospecter", "value": "100800877", "color": "#0000FF", "order": 1, "date_de_conversion": "date_de_conversion_en_a_prospecter", "afficher_performance_equipe" :false },
//   { "label": "Prospecté ✅", "value": "101287087", "color": "#32CD32", "order": 2, "date_de_conversion": "date_de_conversion_en_prospecte__", "afficher_performance_equipe" :false },
//   { "label": "Lead", "value": "lead", "color": "#00FF00", "order": 3, "date_de_conversion": "date_de_conversion_en_lead", "afficher_performance_equipe" :false },
//   { "label": "Pas intéressé", "value": "subscriber", "color": "#FF0000", "order": 4, "date_de_conversion": "date_de_conversion_en_pas_interesse", "afficher_performance_equipe" :true },
//   { "label": "Repasser", "value": "53098103", "color": "#FF4500", "order": 5, "date_de_conversion": "date_de_conversion_en_repasser", "afficher_performance_equipe" :true },
//   { "label": "Mauvaise cible", "value": "53120163", "color": "#8B0000", "order": 6, "date_de_conversion": "date_de_conversion_en_mauvaise_cible", "afficher_performance_equipe" :false },
//   { "label": "Déjà équipé", "value": "60397942", "color": "#8B4513", "order": 7, "date_de_conversion": "date_de_conversion_en_deja_equipe", "afficher_performance_equipe" :false },
//   { "label": "Intéressé", "value": "marketingqualifiedlead", "color": "#FF1493", "order": 8, "date_de_conversion": "date_de_conversion_en_interesse", "afficher_performance_equipe" :true },
//   { "label": "Négociation", "value": "60617581", "color": "#FFD700", "order": 9, "date_de_conversion": "date_de_conversion_en_negociation", "afficher_performance_equipe" :false },
//   { "label": "Carte en attente", "value": "81384565", "color": "#FFA500", "order": 10, "date_de_conversion": "date_de_conversion_en_carte_en_attente", "afficher_performance_equipe" :false },
//   { "label": "Accord digitalisation", "value": "opportunity", "color": "#00FFFF", "order": 11, "date_de_conversion": "date_de_conversion_en_accord", "afficher_performance_equipe" :true },
//   { "label": "Client payant", "value": "customer", "color": "#008000", "order": 12, "date_de_conversion": "date_de_conversion_en_client_premium", "afficher_performance_equipe" :true },
//   { "label": "Client freemium", "value": "evangelist", "color": "#ADFF2F", "order": 13, "date_de_conversion": "date_de_conversion_en_client_freemium", "afficher_performance_equipe" :true },
//   { "label": "Inactif", "value": "81377805", "color": "#000000", "order": 14, "date_de_conversion": "date_de_conversion_en_inactif", "afficher_performance_equipe" :false },
//   { "label": "Désabonné", "value": "salesqualifiedlead", "color": "#8B0000", "order": 15, "date_de_conversion": "date_de_conversion_en_desabonne", "afficher_performance_equipe" :false },
//   { "label": "RDV", "value": "53414538", "color": "#1E90FF", "order": 16, "date_de_conversion": "date_de_conversion_en_rdv", "afficher_performance_equipe" :false },
//   { "label": "Plus de Réponse", "value": "77667414", "color": "#808080", "order": 17, "date_de_conversion": "date_de_conversion_en_plus_de_reponse", "afficher_performance_equipe" :false },
//   { "label": "Pas pris", "value": "79354177", "color": "#FF0000", "order": 18, "date_de_conversion": "date_de_conversion_en_pas_pris", "afficher_performance_equipe" :true }
// ];

export const lifecyclePhases = [
  { "label": "À prospecter", "value": "100800877", "color": "#0000FF", "order": 1, "date_de_conversion": "date_de_conversion_en_a_prospecter", "afficher_performance_equipe": false },
  { "label": "Prospecté ✅", "value": "101287087", "color": "#32CD32", "order": 2, "date_de_conversion": "date_de_conversion_en_prospecte__", "afficher_performance_equipe": false },
  { "label": "Lead", "value": "lead", "color": "#00FF00", "order": 3, "date_de_conversion": "date_de_conversion_en_lead", "afficher_performance_equipe": false },
  { "label": "Mauvaise cible", "value": "53120163", "color": "#8B0000", "order": 4, "date_de_conversion": "date_de_conversion_en_mauvaise_cible", "afficher_performance_equipe": false },
  { "label": "Déjà équipé", "value": "60397942", "color": "#8B4513", "order": 5, "date_de_conversion": "date_de_conversion_en_deja_equipe", "afficher_performance_equipe": false },
  { "label": "Repasser", "value": "53098103", "color": "#FF4500", "order": 6, "date_de_conversion": "date_de_conversion_en_repasser", "afficher_performance_equipe": true },
  { "label": "Pas intéressé", "value": "subscriber", "color": "#FF0000", "order": 7, "date_de_conversion": "date_de_conversion_en_pas_interesse", "afficher_performance_equipe": true },
  { "label": "Intéressé", "value": "marketingqualifiedlead", "color": "#FF1493", "order": 8, "date_de_conversion": "date_de_conversion_en_interesse", "afficher_performance_equipe": true },
  { "label": "Négociation", "value": "60617581", "color": "#FFD700", "order": 9, "date_de_conversion": "date_de_conversion_en_negociation", "afficher_performance_equipe": false },
  { "label": "RDV", "value": "53414538", "color": "#1E90FF", "order": 10, "date_de_conversion": "date_de_conversion_en_rdv", "afficher_performance_equipe": false },
  { "label": "Carte en attente", "value": "81384565", "color": "#FFA500", "order": 11, "date_de_conversion": "date_de_conversion_en_carte_en_attente", "afficher_performance_equipe": false },
  { "label": "Accord digitalisation", "value": "opportunity", "color": "#00FFFF", "order": 12, "date_de_conversion": "date_de_conversion_en_accord", "afficher_performance_equipe": true },
  { "label": "Client payant", "value": "customer", "color": "#008000", "order": 13, "date_de_conversion": "date_de_conversion_en_client_premium", "afficher_performance_equipe": true },
  { "label": "Client freemium", "value": "evangelist", "color": "#ADFF2F", "order": 14, "date_de_conversion": "date_de_conversion_en_client_freemium", "afficher_performance_equipe": true },
  { "label": "Inactif", "value": "81377805", "color": "#000000", "order": 15, "date_de_conversion": "date_de_conversion_en_inactif", "afficher_performance_equipe": false },
  { "label": "Désabonné", "value": "salesqualifiedlead", "color": "#8B0000", "order": 16, "date_de_conversion": "date_de_conversion_en_desabonne", "afficher_performance_equipe": false },
  { "label": "Plus de Réponse", "value": "77667414", "color": "#808080", "order": 17, "date_de_conversion": "date_de_conversion_en_plus_de_reponse", "afficher_performance_equipe": false },
  { "label": "Pas pris", "value": "79354177", "color": "#FF0000", "order": 18, "date_de_conversion": "date_de_conversion_en_pas_pris", "afficher_performance_equipe": true }
];

export const enabledLifecyclePhasesForInvoice  = ["opportunity", "customer", "evangelist", "81377805", "salesqualifiedlead", "77667414", "79354177"];

export function getColorForPhase(value) {
  const phase = lifecyclePhases.find(p => p.value === value);
  return phase ? phase.color || "#000000" : "#000000";
}

export function getOrderForPhase(value) {
  const phase = lifecyclePhases.find(p => p.value === value);
  return phase ? phase.order : 0;
}

export function getLabelForPhase(value) {
  const phase = lifecyclePhases.find(p => p.value === value);
  return phase ? phase.label : null;
}

export function isNullOrEmpty(value) {
  return value === null || value === undefined || value === '';
}

// columnMapping.js
export const columnMapping = {
  "À prospecter": "date_de_conversion_en_a_prospecter",
  "Prospecté ✅": "date_de_conversion_en_prospecte__",
  "Lead": "date_de_conversion_en_lead",
  "Pas intéressé": "date_de_conversion_en_pas_interesse",
  "Repasser": "date_de_conversion_en_repasser",
  "Mauvaise cible": "date_de_conversion_en_mauvaise_cible",
  "Déjà équipé": "date_de_conversion_en_deja_equipe",
  "Intéressé": "date_de_conversion_en_interesse",
  "Négociation": "date_de_conversion_en_negociation",
  "Carte en attente": "date_de_conversion_en_carte_en_attente",
  "Accord digitalisation": "date_de_conversion_en_accord",
  "Client payant": "date_de_conversion_en_client_premium",
  "Client freemium": "date_de_conversion_en_client_freemium",
  "Inactif": "date_de_conversion_en_inactif",
  "Désabonné": "date_de_conversion_en_desabonne",
  "RDV": "date_de_conversion_en_rdv",
  "Plus de Réponse": "date_de_conversion_en_plus_de_reponse",
  "Pas pris": "date_de_conversion_en_pas_pris"
};

export const profiles = [
  { id: 1, value: 'COMMERCIAL' },
  { id: 2, value: 'MANAGER' },
  { id: 3, value: 'SUPERADMIN' },
];

export const shortenName = (fullName) => {
  if (!fullName) return '';
  const [firstName, lastName] = fullName.trim().split(' ');
  if (!lastName) return firstName;
  return `${firstName} ${lastName.charAt(0).toUpperCase()}.`;
}

export const getStatColor = (aggregation) => {
  const colorMappings = [
    { aggregation: "date_des_visites", color: 'rgb(255, 152, 0)' },
    { aggregation: "date_de_conversion_en_accord", color: 'rgb(76, 175, 80)' },
    { aggregation: "date_de_conversion_en_client_premium", color: 'rgb(0, 172, 193)' }
  ]
  const foundMapping = colorMappings.find(mapping => mapping.aggregation === aggregation);
  return foundMapping ? foundMapping.color : 'rgb(255, 152, 0)';
};

export const TIME_LIMIT_NAVIGATE_TO_THE_CLOUD = 24 * 60 * 60 * 1000; //24 heures
export const CUSTOM_LIFECYCLEPHASE_VISIT = 'visit';