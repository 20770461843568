import React, { useEffect, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  ListItemIcon,
  Button,
} from "@mui/material";
import {
  Add as AddIcon,
  CreditCard as CreditCardIcon,
  AccountBalance as AccountBalanceIcon,
} from "@mui/icons-material";
import CreditCardDialog from "./PaymentBottonComponents/CreditCardDialog"; // Importer le nouveau composant
import RIBDialog from "./PaymentBottonComponents/RIBDialog";
import { jwtDecode } from "jwt-decode";
const PaymentButton = ({ onOptionSelect, idCompany, sx }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRIBDialogOpen, setIsRIBDialogOpen] = useState(false);
  const [user, setUser] = useState(false);
  const currentUserToken = localStorage.getItem("TOKEN_USER");


  useEffect(() => {
    if (currentUserToken) {
      const currentUser = jwtDecode(currentUserToken);
      setUser(currentUser);
    }
  }, [currentUserToken]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddCreditCardClick = () => {
    setIsDialogOpen(true);
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleAddRIBClick = () => {
    setIsRIBDialogOpen(true);
    handleMenuClose();
  };

  const handleRIBDialogClose = () => {
    setIsRIBDialogOpen(false);
  };

  return (
    <Box>
      {/* Le bouton avec un style uniforme */}
      <Button
        variant="outlined"
        onClick={handleMenuClick}
        sx={{
          width: 160, // Réduire la largeur
          fontSize: "0.75rem", // Taille de la police plus petite
          padding: "6px 6px", // Réduction du padding
          marginBottom: 0, // Réduire l'espace sous ce bouton
        }}
      >
        Moyen de paiement
      </Button>

      {/* Menu pour choisir l'option */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {/* <MenuItem onClick={handleAddCreditCardClick} disabled>
          <ListItemIcon>
            <CreditCardIcon fontSize="small" />
          </ListItemIcon>
          Ajouter une carte de crédit
        </MenuItem> */}
        <MenuItem onClick={handleAddRIBClick}>
          <ListItemIcon>
            <AccountBalanceIcon fontSize="small" />
          </ListItemIcon>
          Configurer le prélèvement automatique
        </MenuItem>
      </Menu>

      {/* Dialogs */}
      <CreditCardDialog open={isDialogOpen} onClose={handleDialogClose} />
      <RIBDialog open={isRIBDialogOpen} onClose={handleRIBDialogClose} idCompany={idCompany} userEmail={user?.email} />
    </Box>
  );
};

export default PaymentButton;

