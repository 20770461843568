import { commonService } from '../generic.service';
//
const getInvoiceGlobalStatForDateRange = async (teamId, hubspotOwnerId, paymentMethod, startDate, endDate, invoiceType) => {
    hubspotOwnerId = (hubspotOwnerId == null || hubspotOwnerId === '') ? 'all' : hubspotOwnerId;
    const response = await commonService.get(`Invoicestatistic/invoiceglobal?team=${teamId}&hubspotOwnerId=${hubspotOwnerId}&paymentMethod=${paymentMethod}&startDate=${startDate}&endDate=${endDate}&invoiceType=${invoiceType}`);
    const responseResult = {
        invoiceList:response.allInvoiceResult.invoices
    }
    return responseResult;
};

export const InvoiceStatisticService = {
    getInvoiceGlobalStatForDateRange,
};
