import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const CreditCardDialog = ({ open, onClose }) => {
  const fieldHeight = "48px"; // Hauteur réduite des champs

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="xs" // Réduction de la largeur maximale
      fullWidth={false} // Désactive le mode plein écran
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "12px", // Coins arrondis (optionnel)
          width: "400px", // Largeur personnalisée (optionnel)
        },
      }}
    >
      {/* Titre avec un bouton de fermeture */}
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        Entrer les détails de la carte
        {/* Bouton de fermeture */}
        <IconButton onClick={onClose} sx={{ color: "gray" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2,
          }}
        >
          {/* Champ Numéro de carte */}
          <TextField
            label="Numéro de carte"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              sx: {
                top: "-3px", // Ajuster légèrement pour centrer
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: fieldHeight, // Hauteur réduite
              },
            }}
          />

          {/* Ligne avec MM / AA et CVC */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="MM / AA"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                sx: {
                  top: "-3px", // Ajuster légèrement pour centrer
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: fieldHeight, // Hauteur réduite
                },
              }}
            />
            <TextField
              label="CVC"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                sx: {
                  top: "-3px", // Ajuster légèrement pour centrer
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: fieldHeight, // Hauteur réduite
                },
              }}
            />
          </Box>

          {/* Champ Nom sur la carte */}
          <TextField
            label="Nom sur la carte"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              sx: {
                top: "-3px", // Ajuster légèrement pour centrer
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: fieldHeight, // Hauteur réduite
              },
            }}
          />

          {/* Ligne avec Pays et Code postal */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: fieldHeight, // Hauteur réduite
                },
              }}
            >
              <InputLabel
                sx={{
                  top: "-3px", // Ajuster légèrement pour centrer
                }}
              >
                Pays
              </InputLabel>
              <Select
                defaultValue="United States"
                label="Pays"
                sx={{
                  height: fieldHeight, // Hauteur réduite
                }}
              >
                <MenuItem value="United States">États-Unis</MenuItem>
                <MenuItem value="France">France</MenuItem>
                <MenuItem value="Canada">Canada</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Code postal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                sx: {
                  top: "-3px", // Ajuster légèrement pour centrer
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: fieldHeight, // Hauteur réduite
                },
              }}
            />
          </Box>

          {/* Bouton Enregistrer */}
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              height: fieldHeight, // Hauteur réduite pour correspondre aux autres champs
            }}
            onClick={onClose}
          >
            Enregistrer
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreditCardDialog;
