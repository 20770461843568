import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Avatar,
  Menu,
  Box,
  Button,
  MenuItem
} from "@mui/material";
import { styled } from "@mui/system";
import {
  Menu as MenuIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Notifications as NotificationsIcon
} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { CURRENT_USER } from '../constants';
import packageJson from '../../package.json';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  [theme.breakpoints.up("sm")]: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Spacer = styled("div")({
  flexGrow: 1,
});

const RightIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(2.5)
}));

export default function Header({ handleDrawerToggle, toggleDarkMode, darkMode }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [user] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationsClick = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  // Function to get user initials
  const getUserInitials = () => {
    const firstNameInitial = user?.firstName?.charAt(0).toUpperCase() || '';
    const lastNameInitial = user?.lastName?.charAt(0).toUpperCase() || '';
    return `${firstNameInitial}${lastNameInitial}`;
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Box
          component="img"
          src="/assets/images/logowiic-dark.png"
          alt="Logo"
          sx={{
            height: 20,
            marginRight: 2,
            display: 'block',
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
            color: 'text.secondary',
            fontSize: '0.85rem',
          }}
        >
          {packageJson.version}
        </Typography>
        <Spacer />

        {/* <RightIconButton
          color="inherit"
          aria-label="toggle dark mode"
          onClick={toggleDarkMode}
          edge="end"
        >
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </RightIconButton> */}

        <RightIconButton
          color="inherit"
          aria-label="show notifications"
          edge="end"
          onClick={handleNotificationsClick}
        >
          <Badge badgeContent={1} color="secondary">
            <NotificationsIcon />
          </Badge>
        </RightIconButton>
        {/* Notification menu */}
        <Menu
          anchorEl={anchorElNotifications}
          open={Boolean(anchorElNotifications)}
          onClose={handleNotificationsClose}
          PaperProps={{
            style: {
              width: '300px',
            },
          }}
        >
          {/* Example notification */}
          <MenuItem onClick={handleNotificationsClose}>
            <Box display="flex" alignItems="flex-start" width="100%">
              {/* Avatar avec les initiales */}
              <Avatar sx={{ marginRight: 2 }}>
                WM
              </Avatar>

              {/* Contenu de la notification */}
              <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
                <Typography
                  variant="body2"
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal'
                  }}
                >
                  WiiCmenu&nbsp;: Version actuelle 3.0.0
                </Typography>
                <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5 }}>
                  il y a 2h
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        </Menu>

        <RightIconButton
          color="inherit"
          aria-label="account of current user"
          edge="end"
          onClick={handleMenuOpen}
          sx={{
            marginLeft: 4
          }}
        >
          <Avatar>{getUserInitials()}</Avatar>
        </RightIconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              width: 250, // Width of the menu
              padding: '16px' // Add padding for the content
            },
          }}
        >
          {/* User Info Section */}
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Avatar sx={{ margin: '0 auto', width: 60, height: 60 }}>
              {getUserInitials()}
            </Avatar>
            {/* Prénom et nom */}
            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.75rem', mt: 1 }}>
              {user?.firstName} {user?.lastName}
            </Typography>

            {/* Email */}
            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.65rem', mt: 0.5 }}>
              {user?.email}
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button color="warning"
              variant="outlined"
              onClick={handleLogout}
              size="small"
              fullWidth
            >
              Se déconnecter
            </Button>
          </Box>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
}
