import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import mapboxgl from 'mapbox-gl';
import { TextField, Button, Paper, Typography, IconButton, CircularProgress } from '@mui/material';
import { CompanyService } from "../services/companies/company.service";
import { fetchAddressFromCoordinates } from '../services/geocoding/geocoding.service';
import { alertActions } from '../redux/actions';
import { fetchAndStoreCompanies } from '../dataaccess/db';
import { SearchBox } from "@mapbox/search-js-react";
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { useNavigate } from 'react-router-dom';

const POPUP = {
    width: 400,
    height: 400
};

const MAX_ZOOM = 20;

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

const CreateCompany = ({ map, handleAfterCreate }) => {
    const companyRef = useRef({
        name: '',
        address: '',
        tel: '',
        website: '',
        loading: false
    });
    const dispatch = useDispatch();
    const [popupData, setPopupData] = useState(null); // State to store popup data
    const [popupPosition, setPopupPosition] = useState(null);
    const [open, setOpen] = useState(null);
    const navigate = useNavigate()

    let marker = useRef(null);
    let popup = useRef(null);

    const handleSetPopupDate = useCallback((data) => {
        if (!open) {
            const centerX = (window.innerWidth / 2) - (POPUP.width / 2);
            const centerY = (window.innerHeight / 2) - (POPUP.height / 2);

            setPopupPosition({ x: centerX, y: centerY });
            setPopupData(data);

            setOpen(true);
        }
    })

    // Séléction via searchapi
    const handleSearchResult = (result) => {
        if (result && result.features && result.features.length > 0) {
            const coordinates = result.features[0].geometry.coordinates;
            const properties = result.features[0].properties || {};

            // Supprimer l'ancien marqueur s'il existe
            if (marker.current) {
                marker.current.remove();
            }

            // Créer un nouveau marqueur
            const newMarker = new mapboxgl.Marker({
                color: "#b5264f",  // Couleur du marqueur
                offset: [0, -25]  // Décale le marqueur de 10 pixels vers le haut (valeur négative pour décaler vers le haut)
            })
                .setLngLat(coordinates)
                .addTo(map);

            // Créer un popup et l'associer au marqueur
            const newPopup = new mapboxgl.Popup({ offset: 25, maxWidth: '400px' });

            newMarker.setPopup(newPopup);

            companyRef.current.name = properties.name || '';
            companyRef.current.address = properties.full_address || '';
            companyRef.current.tel = properties.tel || '';
            companyRef.current.website = properties.website || '';

            // Sauvegarder le marqueur et le popup dans l'état
            marker.current = newMarker;
            popup.current = newPopup;

            // Centrer la carte sur les coordonnées sélectionnées
            map.flyTo({
                center: coordinates,
                zoom: MAX_ZOOM,
            });

            //console.log("SEARCH = ", result.features[0]);
            // Ajouter un gestionnaire d'événement pour le clic sur le marker
            newMarker.getElement().addEventListener('click', (event) => {
                // Empêcher la propagation du clic au-delà du marker
                event.stopPropagation();

                // console.log("CLICK SEARCH MARKER");
                handleSetPopupDate(result.features[0]);
            });
        }
    };

    const handleCreate = async (data) => {
        companyRef.current.loading = true;
        const currentUserToken = localStorage.getItem("TOKEN_USER");
        handleSetPopupDate(data);

        try {
            const res = await fetchAddressFromCoordinates(data.geometry.coordinates[0], data.geometry.coordinates[1]);
            const today = new Date();
            const formattedDate = today.toISOString().slice(0, 19).replace('T', ' ');
            let companyToCreate = {
                name: companyRef.current.name,
                lifecyclestage: 'lead',
                city: res.city,
                departement: res.departement,
                state: res.state,
                country: res.country,
                firstseenafterclient: data.geometry.coordinates[0],
                secondseenafterclient: data.geometry.coordinates[1],
                address: res.address,
                date_des_visites: formattedDate,
                zip: res.zip
            };

            const company = await CompanyService.createCompany(companyToCreate);
            const lastVisitTimeKey = `${currentUserToken}_${company.id}_lastVisitTime`;
            const updatedLastVisiteTimestamp = new Date();
            localStorage.setItem(lastVisitTimeKey, updatedLastVisiteTimestamp.toISOString());
            handleAfterCreate();

            if (popup.current) popup.current.remove();
            if (marker.current) marker.current.remove();

            await fetchAndStoreCompanies();
            dispatch(alertActions.success("Entreprise créée !"));
            companyRef.current.loading = false;

            if (company.id) {
                //const baseUrl = window.location.origin;
                //const url = `${baseUrl}/home?q=${company.id}`;
                //window.location.href = url;
                navigate(`/home?q=${company.id}`);
                window.location.reload();
            }
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'adresse:', error);
            dispatch(alertActions.error("Création échouée !"));
            companyRef.current.loading = false;
        } finally {
            companyRef.current.loading = false;
            handleClosePopup(); // Clear popup data after creation
        }
    };

    const handleChange = (name, value) => {
        companyRef.current[name] = value;
    };

    useEffect(() => {
        const handleClick = (e) => {
            const features = map.queryRenderedFeatures(e.point, { layers: ['poi-label'] });
            if (!features.length) return;

            const poi = features[0];
            const coordinates = poi.geometry.coordinates.slice();

            if (marker.current) marker.current.remove();

            marker.current = new mapboxgl.Marker({
                color: "#b5264f",  // Couleur du marqueur
                offset: [0, -25]  // Décale le marqueur de 10 pixels vers le haut (valeur négative pour décaler vers le haut)
            })
                .setLngLat(coordinates)
                .addTo(map);

            popup.current = new mapboxgl.Popup({ offset: 25, maxWidth: '400px' });
            marker.current.setPopup(popup.current);

            companyRef.current.name = poi.properties.name || '';
            companyRef.current.address = poi.properties.place_name || '';
            companyRef.current.tel = poi.properties?.tel || '';
            companyRef.current.website = poi.properties?.website || '';

            // Centrer la carte sur les coordonnées du POI
            map.flyTo({
                center: coordinates,
                zoom: MAX_ZOOM, // Ajustez le niveau de zoom selon vos besoins
            });
            handleSetPopupDate(poi);
        };

        map.on('click', handleClick);

        return () => {
            map.off('click', handleClick);
        };
    }, [handleSetPopupDate, map, open]);

    const handleClosePopup = () => {
        setOpen(false);
    };

    return (
        <>
            <div style={{ width: "350px", height: "54px", margin: '0.5rem' }}>
                <SearchBox
                    accessToken={mapboxgl.accessToken}
                    onRetrieve={(result) => handleSearchResult(result)}
                    placeholder="Rechercher un lieu"
                />
            </div>

            {/* Draggable Popup Rendering */}
            {map && open && (
                <Draggable handle=".draggable-handle">
                    <Paper
                        elevation={3}
                        className="popup-container"
                        style={{
                            position: 'absolute',
                            left: popupPosition.x,
                            top: popupPosition.y,
                            padding: "10px",
                            zIndex: 1000,
                            maxWidth: POPUP.width,
                            width: POPUP.width,
                            cursor: 'move',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '5px',
                                //backgroundColor: "#f1f1f1",
                                borderRadius: "5px 5px 0 0",
                            }}
                        >
                            <div className="draggable-handle">
                                <div
                                    style={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        pointerEvents: 'auto', // Réactive les événements de clic pour le texte
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        style={{
                                            cursor: 'move',
                                            padding: '4px 0',
                                            margin: 0,
                                        }}
                                    >
                                        <span style={{ margin: 0 }}>
                                            <strong>Création d'une entreprise</strong>
                                        </span>
                                    </Typography>
                                </div>

                            </div>
                            <div style={{ pointerEvents: 'auto' }}>
                                <IconButton
                                    size="small"
                                    onClick={handleClosePopup}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>

                        <TextField
                            variant="outlined"
                            label="Nom"
                            name="name"
                            defaultValue={companyRef.current.name}
                            fullWidth
                            sx={{ mt: 1 }}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            slotProps={{
                                input: {
                                    readOnly: companyRef.current.loading,
                                    style: { height: '54px' }
                                },
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Téléphone"
                            name="tel"
                            defaultValue={companyRef.current.tel}
                            fullWidth
                            sx={{ mt: 1 }}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            slotProps={{
                                input: {
                                    readOnly: companyRef.current.loading,
                                    style: { height: '54px' }
                                },
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Site web"
                            name="website"
                            defaultValue={companyRef.current.website}
                            fullWidth
                            sx={{ mt: 1 }}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            slotProps={{
                                input: {
                                    readOnly: companyRef.current.loading,
                                    style: { height: '54px' }
                                },
                            }}
                        />
                        <Button
                            variant="outlined"
                            color="success"
                            fullWidth
                            onClick={() => handleCreate(popupData)}
                            sx={{ mt: 1, height: '48px' }}
                            startIcon={companyRef.current.loading && <CircularProgress size={20} color="inherit" />}
                            disabled={companyRef.current.loading}
                        >
                            {!companyRef.current.loading && <span>Créer</span>}
                        </Button>
                    </Paper>
                </Draggable>
            )}
        </>
    )
};

export default CreateCompany;
