import React, { useEffect, useState } from "react";
import { Grid, Box, Paper, MenuItem, Select, InputLabel, FormControl, TextField, IconButton, Button, Tooltip, CircularProgress } from "@mui/material";
import ModelBar from "../components/dashboard/ModelBar";
import ModelBar2 from "../components/dashboard/ModelBar2";
import ModelPie from "../components/dashboard/ModelPie";
import ModelLine from "../components/dashboard/ModelLine";
import Summary from "../components/dashboard/summary";
import { StatisticService } from "../services/statistics";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { TeamService } from "../services/teams/team.service";
import { CURRENT_USER } from "../constants";
import { jwtDecode } from "jwt-decode";
import { CUSTOM_LIFECYCLEPHASE_VISIT, lifecyclePhases } from "../helpers/utils";
import { alertActions } from "../redux/actions";
import { useDispatch } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';

dayjs.locale('fr');

export default function DashboardPage() {
    const [currentUser, setCurrentUser] = useState(localStorage.getItem(CURRENT_USER) !== 'undefined' ? JSON.parse(localStorage.getItem(CURRENT_USER)) : {});
    const today = dayjs();
    const [globalStat, setGlobalStat] = useState([]);
    const [compareStat, setCompareStat] = useState([]);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [teams, setTeams] = useState([]);
    const [members, setMembers] = useState([]);
    const [cycleStageStats, setCycleStageStats] = useState([]);
    const [loading, setLoading] = useState(false);

    const [teamId, setTeamId] = useState(currentUser.teamId || null);
    const [selectedPeriod, setSelectedPeriod] = useState('today');
    const [member, setMember] = useState(currentUser.idOwner || null);
    const [lifecylephase, setLifecylephase] = useState(CUSTOM_LIFECYCLEPHASE_VISIT);
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem('TOKEN_USER');
        if (token) {
            const user = jwtDecode(token);
            setCurrentUser(user);
            setTeamId(user.teamId);
        }
        fetchGlobalStat();
        fetchCompareStat();
        fetchCycleStageStat();
    }, []);

    useEffect(() => {
        fetchTeams();
    }, []);

    useEffect(() => {
        if (teamId !== 'all') {
            const selectedTeam = teams.find(t => t.id === teamId);
            setMembers(selectedTeam ? selectedTeam.members : []);
        }
        else {
            const allMembers = teams.reduce((acc, team) => {
                return acc.concat(team.members);
            }, []);
            setMembers(allMembers);
        }
    }, [teamId, teams]);

    useEffect(() => {


    }, [teamId, member, startDate, endDate, lifecylephase]);

    const fetchGlobalStat = async () => {
        try {
            setGlobalStat(await StatisticService.getGlobalStatForDateRange(
                teamId, member, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')
            ));
        } catch (error) {
            console.error("Error getGlobalStatForDateRange:", error);
            throw error;
        }
    };

    const fetchCompareStat = async () => {
        try {
            setCompareStat(await StatisticService.getMembersCompare(
                lifecylephase, teamId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')
            ));
        } catch (error) {
            console.error("Error getMembersCompare:", error);
            throw error;
        }
    };

    const fetchCycleStageStat = async () => {
        try {
            setCycleStageStats(await StatisticService.getCycleStageStat(
                member, teamId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')
            ));
        } catch (error) {
            console.error("Error getCycleStageStat:", error);
            throw error;
        }
    };


    const handleTeamChange = (event) => {
        setTeamId(event.target.value);
        setMember('');
    };

    const handleMemberChange = (event) => {
        setMember(event.target.value);
    };

    const fetchTeams = async () => {
        try {
            const fetchedTeams = await TeamService.getTeamsWithMembers();
            setTeams(fetchedTeams);
        } catch (error) {
            console.error("Error fetching teams:", error);
        }
    };

    const handleLifecylephaseChange = (event) => {
        setLifecylephase(event.target.value)
    }

    useEffect(() => {
        updateDateRange(selectedPeriod);
    }, [selectedPeriod]);

    const updateDateRange = (period) => {
        let newStartDate, newEndDate;

        switch (period) {
            case 'today':
                newStartDate = today;
                newEndDate = today;
                break;
            case 'yesterday':
                newStartDate = today.subtract(1, 'day');
                newEndDate = today.subtract(1, 'day');
                break;
            case 'thisWeek':
                newStartDate = today.startOf('week');
                newEndDate = today.endOf('week');
                break;
            case 'lastWeek':
                newStartDate = today.subtract(1, 'week').startOf('week');
                newEndDate = today.subtract(1, 'week').endOf('week');
                break;
            case 'thisMonth':
                newStartDate = today.startOf('month');
                newEndDate = today.endOf('month');
                break;
            case 'lastMonth':
                newStartDate = today.subtract(1, 'month').startOf('month');
                newEndDate = today.subtract(1, 'month').endOf('month');
                break;
            default:
                newStartDate = today;
                newEndDate = today;
                break;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);

        if (event.target.value !== 'period') {
            updateDateRange(event.target.value);
        }
    }

    const applyFilters = async () => {
        if (startDate.isAfter(endDate)) {
            dispatch(alertActions.error("La date de début doit précéder la date de fin."));
            return;
        }
        setLoading(true);
        try {
            await Promise.all([
                fetchGlobalStat(),
                fetchCompareStat(),
                fetchCycleStageStat(),
            ]);
        } catch (error) {
            console.error("Erreur lors de la récupération des données:", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box sx={{ padding: 2 }}>
            {/* Filtre Section */}
            <Paper elevation={3} sx={{ marginBottom: 0, padding: 3 }}>
                <Grid container spacing={2}>
                    {/* Dropdown pour sélectionner une équipe */}
                    <Grid item xs={12} sm={2.4}>
                        <FormControl fullWidth>
                            <InputLabel id="team-label">Sélectionner une équipe</InputLabel>
                            <Select
                                labelId="team-label"
                                value={teamId}
                                label="Sélectionner une équipe"
                                onChange={handleTeamChange}
                                disabled={currentUser.profile !== 'SUPERADMIN'}
                            >
                                <MenuItem value="all">Tous</MenuItem>
                                {teams?.map((team) => (
                                    <MenuItem key={team.id} value={team.id}>
                                        {team.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Dropdown pour sélectionner un membre */}
                    <Grid item xs={12} sm={2.4}>
                        <FormControl fullWidth>
                            <InputLabel id="member-label">Sélectionner un membre</InputLabel>
                            <Select
                                labelId="member-label"
                                value={member}
                                label="Sélectionner un membre"
                                onChange={handleMemberChange}
                                disabled={currentUser.profile === 'COMMERCIAL'}
                            >
                                <MenuItem value="all">Tous</MenuItem>
                                {members.map((member) => (
                                    <MenuItem key={member.id} value={member.idOwner}>
                                        {member.firstName} {member.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* Nouveau filtre : Sélection de période */}
                    <Grid item xs={12} sm={2.4}>
                        <FormControl fullWidth>
                            <InputLabel id="period-label">Sélectionner une période</InputLabel>
                            <Select
                                labelId="period-label"
                                value={selectedPeriod}
                                label="Sélectionner une période"
                                onChange={handlePeriodChange}
                            >
                                <MenuItem value="period">Période</MenuItem>
                                <MenuItem value="today">Aujourd'hui</MenuItem>
                                <MenuItem value="yesterday">Hier</MenuItem>
                                <MenuItem value="thisWeek">Cette semaine</MenuItem>
                                <MenuItem value="lastWeek">Semaine dernière</MenuItem>
                                <MenuItem value="thisMonth">Ce mois</MenuItem>
                                <MenuItem value="lastMonth">Mois dernier</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* DatePicker pour la date de début */}
                    <Grid item xs={12} sm={2.2}>
                        <DatePicker
                            label="Date de début"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            sx={{ width: '100%' }}
                            disabled={selectedPeriod !== 'period'}
                        />
                    </Grid>
                    {/* DatePicker pour la date de fin */}
                    <Grid item xs={12} sm={2.2}>
                        <DatePicker
                            label="Date de fin"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            sx={{ width: '100%' }}
                            disabled={selectedPeriod !== 'period'}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {loading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Tooltip title="Appliquer">
                                <IconButton onClick={applyFilters} color="primary">
                                    ✔️
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid> */}
                    <Grid item xs={12} sm={0.4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {loading ? (
                            <CircularProgress size={32} />
                        ) : (
                            <Tooltip title="Appliquer">
                                <Button
                                    onClick={applyFilters}
                                    variant="outlined"
                                    color="green"
                                    sx={{
                                        minWidth: '56px',
                                        minHeight: '56px',
                                        padding: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderColor: 'rgb(76, 175, 80)',
                                        color: 'rgb(76, 175, 80)',
                                    }}
                                >
                                    <SearchIcon style={{ fontSize: '32px' }} />
                                </Button>
                            </Tooltip>
                        )}
                    </Grid>              
                </Grid>
            </Paper>

            {/* Summary et Graphiques */}
            <Summary global={globalStat} />

            <Grid container spacing={3} pl={0} pr={0}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <h3>Performance équipe</h3>
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="dropdown-label">Cycle</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    value={lifecylephase}
                                    onChange={handleLifecylephaseChange}
                                    label="Choisir"
                                >
                                    <MenuItem value={CUSTOM_LIFECYCLEPHASE_VISIT}>Visites</MenuItem>
                                    {lifecyclePhases
                                        .filter(phase => phase.afficher_performance_equipe)
                                        .map(phase => (
                                            <MenuItem key={phase.value} value={phase.value}>
                                                {phase.label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <ModelBar data={compareStat} teams={teams} lifecylephase={lifecylephase} />
                    </Paper>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>Installations et Abonnements</h3>
                        <ModelBar2 />
                    </Paper>
                </Grid> */}

                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>
                            {teamId && teamId !== "all"
                                ? `Performance globale de ${teams.find(team => team.id === teamId)?.name}`
                                : "Performance globale de toute l'équipe"}
                        </h3>
                        <ModelPie data={cycleStageStats} />
                    </Paper>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} sx={{ height: "100%", padding: 2 }}>
                        <h3>Accords Obtenus et Primes</h3>
                        <ModelLine />
                    </Paper>
                </Grid> */}
            </Grid>
        </Box >
    );
}
