import { db } from '../../dataaccess/db';

/**
 * @param {CoordinatePair} centerCoordinates - the {@link CoordinatePair}
 * @return {Promise<Object>}
 */
export const fetchData = async (req) => {
  let { filters, id } = req;
  if (!filters) filters = {};
  let allcompanies = await db.companies.toArray();

  //Ne pas afficher ceux qui sont supprimés et ceux avec address nulle
  allcompanies = allcompanies.filter(feature => {
    return feature.date_de_suppression_en_local == null && (feature.address != null || feature.lifecyclestage == 'customer' || feature.lifecyclestage == 'evangelist'); // 
  });

  //Local test
  if (process.env.NODE_ENV !== 'production') {
    // allcompanies = allcompanies.filter(feature => {
    //   return feature.hubspot_owner_id === '981044519';
    // });
  }

  //Filtrer
  allcompanies = allcompanies.filter(feature => {
    return (
      (!id || feature.id === id) &&

      (!filters.cycles || filters.cycles?.length === 0 ||
        filters.cycles?.some(cycle => cycle.value === feature.lifecyclestage)) &&

      (!filters.companyNames || filters.companyNames?.length === 0 ||
        filters.companyNames?.some(company => company.name === feature.name)) &&

      (!filters.companyOwners || filters.companyOwners?.length === 0 ||
        filters.companyOwners?.some(owner => owner.id === feature.hubspot_owner_id)) &&

      (!filters.city || feature.city?.toLowerCase().includes(filters.city?.toLowerCase())) &&
      (!filters.department || feature.department?.toLowerCase().includes(filters.department?.toLowerCase())) &&
      (!filters.state || feature.state?.toLowerCase().includes(filters.state?.toLowerCase())) &&
      (!filters.country || feature.country?.toLowerCase().includes(filters.country?.toLowerCase()))
    );
  });
  const geojsonFeatures = allcompanies.map(feature => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [feature.firstseenafterclient, feature.secondseenafterclient]
    },
    properties: {
      ...feature
    }
  }));

  return Promise.resolve({
    type: "FeatureCollection",
    features: geojsonFeatures
  });
};

// export const fetchDataCompanyNames = async (search) => {
//   const companiesName = await db.companies
//     .where('name')
//     .startsWithIgnoreCase(search)
//     .distinct()
//     .toArray();

//   return companiesName.map(company => ({
//     name: company.name
//   }));
// };

export const fetchDataCompanyNames = async (search) => {
  const companiesName = await db.companies
    .toArray(); // Récupère tous les enregistrements

  const filteredNames = companiesName
    .filter(company => company.name?.toLowerCase().includes(search?.toLowerCase())) // Filtre avec contains
    .map(company => company.name)
    .filter((value, index, self) => self.indexOf(value) === index); // Supprime les doublons

  return filteredNames.map(name => ({ name }));
};

// export const fetchDataCompanyOwners = async (search) => {
//   const companiesOwner = await db.companies
//     .where('hubspot_owner_id')
//     .startsWithIgnoreCase(search)
//     .distinct()
//     .toArray();

//   return companiesOwner.map(company => ({
//     owner: company.hubspot_owner_id
//   }));
// };

export const fetchDataCompanyOwners = async () => {
  const companiesOwner = await db.companies
    .toArray(); // Récupère tous les enregistrements

  const filteredOwners = companiesOwner
    //.filter(company => company.hubspot_owner_id?.toLowerCase().includes(search.toLowerCase())) // Filtre avec contains
    .map(company => company.hubspot_owner_id)
    .filter((value, index, self) => self.indexOf(value) === index);

  const sortedOwners = filteredOwners.sort((a, b) => a.localeCompare(b));
  return sortedOwners.map(owner => ({ owner }));
};

export const updateLifecycleStage = async (id, newLifecycleStage) => {
  try {
    const company = await db.companies.get(id);
    if (!company) {
      console.log(`L'enregistrement avec l'ID ${id} n'existe pas.`);
      return;
    }
    await db.companies.update(id, { lifecyclestage: newLifecycleStage });
    console.log(`Lifecyclestage mis à jour pour l'ID ${id} avec la valeur ${newLifecycleStage}`);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du lifecyclestage :", error);
  }
};

export const updateNombreVisite = async (id, nombre_de_visite) => {
  try {
    const company = await db.companies.get(id);
    if (!company) {
      console.log(`L'enregistrement avec l'ID ${id} n'existe pas.`);
      return;
    }
    await db.companies.update(id, { nombre_de_visite: nombre_de_visite });
    console.log(`nombre_de_visite mis à jour pour l'ID ${id} avec la valeur ${nombre_de_visite}`);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du nombre_de_visite :", error);
  }
};

export const updateLastVisiteTimestamp = async (id, lastvisit) => {
  try {
    const company = await db.companies.get(id);
    if (!company) {
      console.log(`L'enregistrement avec l'ID ${id} n'existe pas.`);
      return;
    }
    await db.companies.update(id, { lastvisit: lastvisit });
    console.log(`nombre_de_visite mis à jour pour l'ID ${id} avec la valeur ${lastvisit}`);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du nombre_de_visite :", error);
  }
};

export const updateOwner = async (id, hubspot_owner_id) => {
  try {
    const company = await db.companies.get(id);
    if (!company) {
      console.log(`L'enregistrement avec l'ID ${id} n'existe pas.`);
      return;
    }
    await db.companies.update(id, { hubspot_owner_id: hubspot_owner_id });
    console.log(`hubspot_owner_id mis à jour pour l'ID ${id} avec la valeur ${hubspot_owner_id}`);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du lifecyclestage :", error);
  }
};

export const updateNotes = async (id, notes_sur_l_inscription) => {
  try {
    const company = await db.companies.get(id);
    if (!company) {
      console.log(`L'enregistrement avec l'ID ${id} n'existe pas.`);
      return;
    }
    await db.companies.update(id, { notes_sur_l_inscription: notes_sur_l_inscription });
    console.log(`Lifecyclestage mis à jour pour l'ID ${id} avec la valeur ${notes_sur_l_inscription}`);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du lifecyclestage :", error);
  }
};

export const createCompany = async (company) => {
  try {
    const id = await db.companies.add(company);

    console.log(`Nouvelle company insérée avec l'ID ${id}`);
    return id;
  } catch (error) {
    console.error("Erreur lors de l'insertion de la nouvelle company :", error);
    throw error;
  }
};

export async function searchExistingCoordinates(firstSeenValue, secondSeenValue) {
  try {
    const results = await db.companies
      .where({
        firstseenafterclient: firstSeenValue,
        secondseenafterclient: secondSeenValue
      })
      .toArray();

    return results;
  } catch (error) {
    console.error("Erreur lors de la recherche:", error);
    return [];
  }
};

export const deleteCompany = async (id) => {
  try {
    await db.companies.delete(id);
    console.log(`Company avec l'ID ${id} supprimée.`);
  } catch (error) {
    console.error("Erreur lors de la suppression de la company :", error);
    throw error;
  }
};

export const updateConversionDate = async (id, columnName, date) => {
  try {
    const company = await db.companies.get(id);
    if (!company) {
      return;
    }
    const updateObject = {};
    updateObject[columnName] = date;
    await db.companies.update(id, updateObject);
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la date de conversion :", error);
  }
};

export const updateDatesDesVisites = async (id, date_des_visites) => {
  try {
    const company = await db.companies.get(id);
    if (!company) {
      console.log(`L'enregistrement avec l'ID ${id} n'existe pas.`);
      return;
    }
    await db.companies.update(id, { date_des_visites: date_des_visites });
  } catch (error) {
    console.error("Erreur lors de la mise à jour du date_des_visites :", error);
  }
};

export const fetchCompanyById = async (id) => {
  try {
    const company = await db.companies.get(id);

    if (!company) {
      console.log(`Aucune entreprise trouvée avec l'ID ${id}`);
      return null;
    }

    return company;
  } catch (error) {
    console.error("Erreur lors de la récupération de l'entreprise :", error);
    throw error;
  }
};
