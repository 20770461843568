import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ModelBarInvoice = ({ invoicesList, teamId, teams, invoiceStatus }) => {


    let labels = [];
    let paidData = [];
    let unpaidData = [];

    // Si teamId est 'all', on calcule les données pour toutes les équipes
    if (teamId === 'all') {
        // Parcours des équipes
        teams?.forEach((team) => {
            labels.push(team.name);

            let paidSum = 0;
            let unpaidSum = 0;

            // Parcours des factures pour calculer la somme en fonction de fbInvoicePayementMode
            invoicesList?.forEach((invoice) => {
                if (invoice.team === team.name) {
                    const totalTTC = parseFloat(JSON.parse(invoice.fbProductsDetails)?.cost?.totalTTC);

                    if ((invoice.fbInvoicePayementMode !== "" && invoice.fbInvoicePayementMode !== null)) {
                        paidSum += totalTTC;
                    } else {
                        unpaidSum += totalTTC;
                    }
                }
            });

            paidData.push(paidSum);
            unpaidData.push(unpaidSum);
        });
    } else {
        const team = teams?.find(t => t.id === teamId);
        labels = team?.members?.map(member => `${member.firstName} ${member.lastName}`); // Récupère les membres de l'équipe

        paidData = [];
        unpaidData = [];

        // Calcul des sommes pour cette équipe
        team?.members?.forEach((member) => {
            let paidSum = 0;
            let unpaidSum = 0;

            invoicesList?.forEach((invoice) => {
                if (invoice.team === team.name && invoice.owner === `${member.firstName} ${member.lastName}`) {
                    const totalTTC = parseFloat(JSON.parse(invoice?.fbProductsDetails)?.cost?.totalTTC);

                    if ((invoice.fbInvoicePayementMode !== "" && invoice.fbInvoicePayementMode !== null)) {
                        paidSum += totalTTC;
                    } else {
                        unpaidSum += totalTTC;
                    }
                }
            });
            paidData.push(paidSum);
            unpaidData.push(unpaidSum);
        });
    }

    // Génération des datasets en fonction du statut des factures
    let datasets = [];
    if (invoiceStatus === 'all') {
        datasets = [
            {
                label: 'Factures payées',
                data: paidData,
                backgroundColor: 'rgb(76, 175, 80)', // Couleur verte
            },
            {
                label: 'Factures impayées',
                data: unpaidData,
                backgroundColor: 'rgb(255 103 103)', // Couleur rouge
            },
        ];
    } else if (invoiceStatus === 'paid') {
        datasets = [
            {
                label: 'Factures payées',
                data: paidData,
                backgroundColor: 'rgb(76, 175, 80)', // Couleur verte
            },
        ];
    } else if (invoiceStatus === 'unpaid') {
        datasets = [
            {
                label: 'Factures impayées',
                data: unpaidData,
                backgroundColor: 'rgb(255 103 103)', // Couleur rouge
            },
        ];
    }

    const chartData = {
        labels: labels,
        datasets: datasets,
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.raw + ' €';
                    },
                },
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return value + ' €';
                    },
                },
            },
        },
    };

    return (

        <>
            <Typography variant="h6" gutterBottom>
                {invoiceStatus === 'all' && 'Toutes les factures'}
                {invoiceStatus === 'paid' && 'Factures payées'}
                {invoiceStatus === 'unpaid' && 'Factures impayées'}
            </Typography>
            <Bar data={chartData} options={options} />
        </>

    );
};

export default ModelBarInvoice;
