import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, FormControl, InputLabel, Select, MenuItem, InputAdornment, CircularProgress, Divider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BuildIcon from '@mui/icons-material/Build';
import EuroIcon from '@mui/icons-material/Euro';
import RedeemIcon from '@mui/icons-material/Redeem';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { lifecyclePhases, getColorForPhase } from '../../helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Summary = ({ global }) => {
    const [open, setOpen] = useState(false);
    const [popupContent, setPopupContent] = useState({ title: '', companies: [] });
    const [selectedLifecycle, setSelectedLifecycle] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sortByDate = (companies) => {
        if (!Array.isArray(companies)) {
            console.warn("Expected an array but received:", companies);
            return [];
        }
        return companies.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const abonnementsSum = global?.abonnements?.abonnements && global?.abonnements?.abonnements.length > 0
        ? global?.abonnements?.abonnements.reduce((sum, abonnement) => {
            const fbProductsDetails = abonnement.fbProductsDetails ? JSON.parse(abonnement.fbProductsDetails) : null;
            if (fbProductsDetails && fbProductsDetails.cost) {
                const totalHT = parseFloat(fbProductsDetails.cost.totalHT);
                const discountValue = parseFloat(fbProductsDetails.cost.discountValue) || 0;
                const amount = totalHT - discountValue;
                return sum + (isNaN(amount) ? 0 : amount);
            }
            return sum;
        }, 0)
        : 0;

    const dashboardData = [
        {
            icon: <VisibilityIcon style={{ fontSize: 40, color: '#FF9800' }} />,
            title: 'Visites',
            value: global?.visites?.companies.length,
            footer: 'Liste de visites',
            companies: global?.visites?.companies,
            footerStyle: { color: '#d32f2f' },
        },
        {
            icon: <CheckCircleIcon style={{ fontSize: 40, color: '#2196F3' }} />,
            title: 'Obtenus',
            value: global?.accords?.companies.length,
            companies: sortByDate(global?.accords?.companies),
            footer: 'Liste d\'accords',
        },
        {
            icon: <VerifiedUserIcon style={{ fontSize: 40, color: '#4CAF50' }} />,
            title: 'Validés',
            value: global?.accords_valide?.companies.length,
            companies: sortByDate(global?.accords_valide?.companies),
            footer: 'Liste d\'accords',
        },
        {
            icon: <BuildIcon style={{ fontSize: 40, color: '#F44336' }} />,
            title: 'Installations',
            value: global?.installation?.companies.length,
            companies: sortByDate(global?.installation?.companies),
            footer: 'Liste d\'installations',
        },
        {
            icon: <EuroIcon style={{ fontSize: 40, color: '#FF9800' }} />,
            title: 'Abonnements',
            value: abonnementsSum !== 0 ? `${abonnementsSum.toFixed(2)} €` : '0',
            companies: sortByDate(global?.abonnements?.abonnements),
            footer: 'Détails des abonnements',
        },
    ];

    const handleFooterClick = (title, companies) => {
        setPopupContent({ title, companies });
        setOpen(true);
    };

    const handleRedirectToCompany = (companyId) => {
        const baseUrl = window.location.origin;
        const url = `${baseUrl}/home?q=${companyId}`;
        window.open(url, '_blank');
    };

    const getLifecycleLabel = (value) => {
        const phase = lifecyclePhases.find(phase => phase.value === value);
        return phase ? phase.label : "";
    };

    const setSelectedLyfeCycle = (e) => {
        setSelectedLifecycle(e);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const sortedCompanies = [...popupContent?.companies]
        .filter((company) =>
            selectedLifecycle ? company.previous_lifecyclestage === selectedLifecycle : true
        )
        .sort((a, b) => {
            if (!sortConfig.key) return 0;

            let aValue = a[sortConfig.key]?.toLowerCase();
            let bValue = b[sortConfig.key]?.toLowerCase();

            if (sortConfig.key === 'previous_lifecyclestage') {
                aValue = getLifecycleLabel(aValue);
                bValue = getLifecycleLabel(bValue);
            }
            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });

    return (
        <Box pt={2} pb={2}>
            <Grid container spacing={3}>
                {dashboardData.map((data, index) => (
                    <Grid item xs={12} sm={6} md={2.4} key={index}>
                        <Card>
                            <Box display="flex" alignItems="center" p={2}>
                                <Box mr={2}>{data.icon}</Box>
                                <CardContent>
                                    <Typography variant="subtitle2" color="textSecondary" pb={1.2}>
                                        {data.title}
                                    </Typography>
                                    <Typography variant="h5" fontWeight="bold">
                                        {data.value}
                                    </Typography>
                                    <Typography
                                        pt={1.4}
                                        variant="body1"
                                        sx={{
                                            ...data.footerStyle,
                                            fontSize: '.6rem',
                                            cursor: 'pointer',
                                            whiteSpace: 'nowrap',     // Garde le texte sur une seule ligne
                                            overflow: 'hidden',      // Cache tout ce qui déborde
                                            textOverflow: 'ellipsis', // Ajoute "..." si le texte est trop long
                                            display: 'block',         // Assure que les styles fonctionnent
                                            width: '100%',            // S'assure que le conteneur a une largeur définie
                                        }}
                                        onClick={() => handleFooterClick(data.footer, data.companies)}
                                    >
                                        {data.footer}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={open} onClose={handleClose} sx={{
                '& .MuiDialog-paper': {
                    width: isMobile ? '96%' : 600,
                    margin: isMobile ? 2 : 'auto',

                    height: isMobile ? 'calc(100vh - 200px)' : 650,
                },
            }}>
                <DialogTitle color='warning' sx={{ padding: 2, textAlign: 'center', fontWeight: 600 }}>{popupContent.title}</DialogTitle>

                {/* Champ de filtre par cycle de vie */}
                {popupContent.title === 'Liste de visites' &&
                    <Box p={2}>
                        <FormControl fullWidth>
                            <InputLabel>Phase de cycle de vie</InputLabel>
                            <Select
                                value={selectedLifecycle}
                                label="Phase de cycle de vie"
                                onChange={(e) => setSelectedLyfeCycle(e.target.value)}
                                style={{ color: getColorForPhase(selectedLifecycle) }}
                                endAdornment={
                                    lifecyclePhases && (
                                        <InputAdornment position="end">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                            </div>
                                        </InputAdornment>
                                    )
                                }
                            >
                                <MenuItem key={0} value={''}>
                                    <span style={{
                                        display: 'inline-block',
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        marginRight: '8px'
                                    }}></span>
                                    Tous
                                </MenuItem>
                                {lifecyclePhases
                                    .map(phase => (
                                        <MenuItem key={phase.value} value={phase.value}>
                                            <span style={{
                                                display: 'inline-block',
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                backgroundColor: phase.color,
                                                marginRight: '8px'
                                            }}></span>
                                            {phase.label}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                }
                <DialogContent sx={{ padding: 2 }}>
                    <Table>
                        {popupContent.title === 'Liste de visites' ? (
                            <>
                                {/* Tableau des entreprises */}
                                <Table>
                                    <TableHead>
                                        {popupContent.companies.length > 0 && (
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'owner'}
                                                        direction={sortConfig.key === 'owner' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('owner')}
                                                    >
                                                        ENTREPRISE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'previous_lifecyclestage'}
                                                        direction={sortConfig.key === 'previous_lifecyclestage' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('previous_lifecyclestage')}
                                                    >
                                                        CYCLE DE VIE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'createdAt'}
                                                        direction={sortConfig.key === 'createdAt' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('createdAt')}
                                                    >
                                                        DATE ET HEURE
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableHead>
                                    <TableBody>
                                        {sortedCompanies.map((company, index) => (
                                            <TableRow key={`${company.id}-${index}`}>
                                                <TableCell
                                                    onClick={() => handleRedirectToCompany(company.id)}
                                                    sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}
                                                >
                                                    {company.name}
                                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                                        {company.owner}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {getLifecycleLabel(company.previous_lifecyclestage)}
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {new Date(company.createdAt).toLocaleString('fr-FR', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {/* Gestion de l'affichage si aucune entreprise ne correspond au filtre */}
                                        {sortedCompanies.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                    <Typography>Pas de détails</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        ) : popupContent.title === 'Détails des abonnements' ? (
                            <>
                                {/* Tableau des entreprises */}
{/* <Typography variant="h6" sx={{ marginBottom: 1 }}>
    Recap
</Typography>
<div>
    <Table sx={{ tableLayout: 'fixed', width: '400px' }}>
        <TableHead>
            {popupContent?.companies.length > 0 && (
                <TableRow>
                    <TableCell
                        sx={{
                            fontWeight: 600,
                            padding: '2px 4px', // Réduction du padding
                            borderBottom: 'none',
                            width: '20%', // Largeur réduite
                            minWidth: '100px', // Largeur minimale
                            maxWidth: '100px', // Largeur maximale pour limiter l'expansion
                        }}
                    >
                        <TableSortLabel>Les services</TableSortLabel>
                    </TableCell>
                    <TableCell
                        sx={{
                            fontWeight: 600,
                            padding: '2px 4px', // Réduction du padding
                            borderBottom: 'none',
                            width: '20%',
                            textAlign: 'center',
                            minWidth: '80px',
                            maxWidth: '100px',
                        }}
                    >
                        <TableSortLabel>Quantité</TableSortLabel>
                    </TableCell>
                    <TableCell
                        sx={{
                            fontWeight: 600,
                            padding: '2px 4px', // Réduction du padding
                            borderBottom: 'none',
                            width: '20%',
                            textAlign: 'center',
                            minWidth: '80px',
                            maxWidth: '100px',
                        }}
                    >
                        <TableSortLabel>Total</TableSortLabel>
                    </TableCell>
                </TableRow>
            )}
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell
                    sx={{
                        padding: '2px 4px', // Réduction du padding
                        borderBottom: 'none',
                    }}
                >
                    -Service 1
                </TableCell>
                <TableCell
                    sx={{
                        padding: '2px 4px', // Réduction du padding
                        borderBottom: 'none',
                        textAlign: 'center',
                    }}
                >
                    3
                </TableCell>
                <TableCell
                    sx={{
                        padding: '2px 4px', // Réduction du padding
                        borderBottom: 'none',
                        textAlign: 'center',
                    }}
                >
                    40 Euro
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    sx={{
                        padding: '2px 4px', // Réduction du padding
                        borderBottom: 'none',
                    }}
                >
                    -Service 2
                </TableCell>
                <TableCell
                    sx={{
                        padding: '2px 4px', // Réduction du padding
                        borderBottom: 'none',
                        textAlign: 'center',
                    }}
                >
                    3
                </TableCell>
                <TableCell
                    sx={{
                        padding: '2px 4px', // Réduction du padding
                        borderBottom: 'none',
                        textAlign: 'center',
                    }}
                >
                    40 Euro
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
</div> */}

    {/* Ligne de séparation
    <Divider sx={{ marginY: 2 }} /> */}

                                <Table>
                                    <TableHead>
                                        {popupContent?.companies.length > 0 && (
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'owner'}
                                                        direction={sortConfig.key === 'owner' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('owner')}
                                                    >
                                                        ENTREPRISE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'previous_lifecyclestage'}
                                                        direction={sortConfig.key === 'previous_lifecyclestage' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('previous_lifecyclestage')}
                                                    >
                                                        TYPE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'createdAt'}
                                                        direction={sortConfig.key === 'createdAt' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('createdAt')}
                                                    >
                                                        QTE
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                    <TableSortLabel
                                                        active={sortConfig.key === 'createdAt'}
                                                        direction={sortConfig.key === 'createdAt' ? sortConfig.direction : 'asc'}
                                                        onClick={() => handleSort('createdAt')}
                                                    >
                                                        MONTANT
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableHead>
                                    <TableBody>
                                        {popupContent?.companies?.map((abonnement, index) => (
                                            <TableRow key={`${abonnement.id}-${index}`}>
                                                <TableCell
                                                    onClick={() => handleRedirectToCompany(abonnement.fbHubspotCompanyId)}
                                                    sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}
                                                >
                                                    {abonnement.fbCompanyName}
                                                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                                        {abonnement.fbHubspotOwner}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {abonnement.fbProductsDetails && JSON.parse(abonnement.fbProductsDetails)?.products
                                                        ? JSON.parse(abonnement.fbProductsDetails).products.map((product, index) => (
                                                            <div key={index}>
                                                                - {product.name}
                                                            </div>
                                                        ))
                                                        : 'Aucun produit'}
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {abonnement.fbProductsDetails && JSON.parse(abonnement.fbProductsDetails)?.products
                                                        ? JSON.parse(abonnement.fbProductsDetails).products.map((product, index) => (
                                                            <div key={index}>
                                                                {product.qty}
                                                            </div>
                                                        ))
                                                        : '0'}
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {abonnement.fbProductsDetails ? (
                                                        (() => {
                                                            const fbProductsDetails = JSON.parse(abonnement.fbProductsDetails);
                                                            const totalHT = parseFloat(fbProductsDetails.cost.totalHT);
                                                            const discountValue = parseFloat(fbProductsDetails.cost.discountValue) || 0;
                                                            const amount = totalHT - discountValue;
                                                            return isNaN(amount) ? '0' : amount.toFixed(2) + ' €';  // On formate avec 2 décimales
                                                        })()
                                                    ) : '0 €'}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {/* Gestion de l'affichage si aucune entreprise ne correspond au filtre */}
                                        {popupContent?.companies.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <Typography>Pas de détails</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        ) : (
                            <>
                                <TableHead>
                                    {popupContent?.companies.length > 0 && (
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>ENTREPRISE</TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>
                                                {popupContent?.title === 'Liste d\'installations' ? 'INSTALLATEUR' : 'PROSPECTEUR'}
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 600, padding: 1 }}>DATE ET HEURE</TableCell>
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {popupContent?.companies.length > 0 ? (
                                        popupContent?.companies.map((company) => (
                                            <TableRow key={company.id}>
                                                <TableCell onClick={() => handleRedirectToCompany(company.id)}  sx={{ padding: 1, cursor: 'pointer', color: '#4caf50' }}>
                                                    {company.name}
                                                </TableCell>
                                                <TableCell sx={{ padding: 1 }}>{company.owner}</TableCell>
                                                <TableCell sx={{ padding: 1 }}>
                                                    {new Date(company.createdAt).toLocaleString('fr-FR', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography>Pas de détails</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </>
                        )}
                    </Table >

                </DialogContent >
                <DialogActions style={{ justifyContent: 'center', marginBottom: 20 }}>
                    <Button onClick={handleClose} color="warning" variant="outlined" style={{ width: 150 }}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog >
        </Box >
    );
};

export default Summary;
