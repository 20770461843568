import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';

const DiscountDialog = ({
  open,
  onClose,
  onApply,
  tempDiscount,
  setTempDiscount,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center', textTransform: 'uppercase'}}>Ajouter une réduction</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box display="flex" alignItems="center" mt={0} mb={1}>
          <TextField
            type="number"
            value={tempDiscount}
            onChange={(e) => setTempDiscount(e.target.value)}
            inputProps={{ style: { textAlign: 'center', width: '60px' } }}
          />
          <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
            %
          </Typography>
        </Box>
        <Typography variant="body2" style={{ textAlign: 'center', color: '#6c757d' }}>
            du sous-total de la facture
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: '16px' }}>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ marginRight: '8px', textTransform: 'none' }}
        >
          Annuler
        </Button>
        <Button
          onClick={onApply}
          variant="outlined"
          color="success"
          style={{ textTransform: 'none' }}
        >
          Appliquer la réduction
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountDialog;